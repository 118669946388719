import React from 'react';
import Link from 'adapter/next/link';
import { CaretRight } from 'phosphor-react';
import { ExternalContentWrapper, contentWrapperStyle } from '@konsus/superside-kit';
import { SanityBlockComponent } from '@konsus/sanity-components/src/SanityBlockComponent';
import { Box, cn } from '@superside/ui';
import { Superscript, VisualAsset } from '../../../components';
import { type DataBlockContentProps, type DataBlockProps } from '../types';

const DataBlockContent: React.FC<DataBlockContentProps> = ({
  superscript,
  description,
  assetExists,
  asset,
  ctaRichText,
  className
}) => {
  return (
    <div
      className={cn(
        className,
        'content-stretch group relative basis-full flex-row items-stretch justify-between'
      )}
    >
      <div className='1280:max-w-[calc(400px+theme(spacing.8)*2)] 1280:p-8 relative z-10 flex flex-1 flex-col justify-between p-5 pb-4 pt-4'>
        {superscript ? (
          <Superscript className='<1280:text-xs text-sm' content={superscript} />
        ) : null}

        {description ? (
          <ExternalContentWrapper
            className={cn(
              contentWrapperStyle,
              '1280:[&_div_h2]:text-[69px] 1280:[&_div_h3]:text-[40px] 1280:[&_div_p]:text-base mx-0 mb-0 mt-auto [&_div_h2]:text-[46px] [&_div_h2]:font-bold [&_div_h2]:leading-[120%] [&_div_h3]:mb-2 [&_div_h3]:text-2xl [&_div_h3]:font-bold [&_div_h3]:leading-[120%] [&_div_p]:m-0 [&_div_p]:p-0 [&_div_p]:text-sm [&_div_p_a]:no-underline  [&_div_p_a]:hover:underline'
            )}
          >
            <SanityBlockComponent blocks={description} />
          </ExternalContentWrapper>
        ) : null}

        {ctaRichText ? (
          <ExternalContentWrapper
            className={cn(
              contentWrapperStyle,
              '[&_a]:text-bor-foreground',
              'before:content-empty',
              'before:duration-400',
              'before:bg-bor-foreground',
              'before:absolute',
              'before:-bottom-0.5',
              'before:left-0',
              'before:h-px',
              'before:w-0',
              'before:opacity-50',
              'before:transition-[width]',
              'before:duration-200',
              'before:ease-in-out',
              'relative',
              'mt-2',
              'flex',
              'w-fit',
              'flex-row',
              'items-center',
              '[&_p]:!m-0',
              '[&_p]:!shrink-0',
              '[&_p]:!p-0',
              '[&_p]:!text-sm',
              '[&_p]:!font-semibold',
              '[a_&:hover]:no-underline',
              'group-hover:before:w-full',
              'group-hover:before:transition-[width]',
              'group-hover:before:duration-200',
              'group-hover:before:ease-in-out'
            )}
          >
            <SanityBlockComponent blocks={ctaRichText} />
            <CaretRight
              weight='bold'
              className='m-0 -mb-0.5 ml-0.5 h-3 w-3 transition-[margin] duration-200 ease-in-out group-hover:ml-1 group-hover:transition-[margin] group-hover:duration-200 group-hover:ease-in-out'
            />
          </ExternalContentWrapper>
        ) : null}
      </div>

      {assetExists && asset ? (
        <div
          className={
            '1280:flex absolute inset-0 z-0 m-0 hidden h-full w-full flex-col items-center justify-center transition-transform duration-300 ease-in-out [&_img]:!h-full [&_img]:!w-full [&_div]:!h-full [&_div]:max-h-full [&_div]:!w-full [&_span]:!h-full [&_span]:!w-full'
          }
        >
          <VisualAsset
            {...asset}
            objectFit='cover'
            objectPosition='right bottom'
            className='duration-200 ease-in-out group-hover:scale-105 group-hover:duration-200 group-hover:ease-in-out'
            requestWidth={1183}
          />
        </div>
      ) : null}
    </div>
  );
};

export const DataBlock: React.FC<DataBlockProps> = ({
  superscript,
  description,
  asset,
  link,
  mobile
}) => {
  const { ctaUrl, ctaRichText, isTargetBlank, hasNoReferrer } = link || {};
  const assetExists = Boolean(
    asset &&
      (asset.image ||
        asset.inlineVideo ||
        asset.youtubeVideoId ||
        asset.wistiaVideoId ||
        asset.lottie)
  );
  const dataBlockProps = {
    superscript,
    description,
    assetExists,
    asset,
    ctaUrl,
    ctaRichText,
    isTargetBlank,
    hasNoReferrer
  };

  const dataBlockInnerContainer = (
    <>
      <DataBlockContent {...dataBlockProps} className='768:flex hidden' />
      <DataBlockContent {...dataBlockProps} {...mobile} className='768:hidden flex' />
    </>
  );

  return description ? (
    <Box
      className={cn(
        'col-span-3 flex min-h-[200px] min-w-[200px] snap-center snap-always flex-row items-stretch justify-between overflow-hidden rounded-lg',
        assetExists && 'col-span-5 min-w-[296px]'
      )}
      glassy='fill'
    >
      {ctaUrl ? (
        <Link
          href={ctaUrl}
          target={isTargetBlank ? '_blank' : '_self'}
          rel={`noopener ${hasNoReferrer ? 'noreferrer' : ''}`}
          className='flex h-full w-full text-inherit no-underline'
        >
          {dataBlockInnerContainer}
        </Link>
      ) : (
        dataBlockInnerContainer
      )}
    </Box>
  ) : null;
};

export default DataBlock;
