import React from 'react';
import { SectionHeader, PageSection } from '../../components';
import { type DataBlocksProps } from './types';
import DataBlocksWrapper from './components/DataBlocksWrapper';

const NUMBER_OF_ELEMENTS_IN_ROW = 3;

export const DataBlocks: React.FC<DataBlocksProps> = ({
  header,
  ctaButton,
  blocks,
  paddingTop,
  paddingBottom
}) => {
  const chunkedBlocks = Array.from(
    { length: Math.ceil((blocks?.length || 0) / NUMBER_OF_ELEMENTS_IN_ROW) },
    (_, index) =>
      blocks?.slice(
        index * NUMBER_OF_ELEMENTS_IN_ROW,
        index * NUMBER_OF_ELEMENTS_IN_ROW + NUMBER_OF_ELEMENTS_IN_ROW
      )
  );

  return (
    <>
      <PageSection paddingTop={paddingTop} paddingBottom='none'>
        {header && (
          <SectionHeader {...header} ctaButton={header?.ctaButton || ctaButton} flex={true} />
        )}
      </PageSection>
      <PageSection paddingTop='none' paddingBottom={paddingBottom} unlimitedMobile>
        <div className='1280:gap-4 flex flex-col gap-3'>
          {chunkedBlocks.map((blocks, idx) => (
            <DataBlocksWrapper
              key={`dataBlockWrapper${idx}`}
              blocks={blocks}
              isEven={(idx + 1) % 2 === 0}
            />
          ))}
        </div>
      </PageSection>
    </>
  );
};

export default DataBlocks;
