import React, { useRef } from 'react';
import { useScrollPosition } from '@konsus/hooks';
import { cn } from '@superside/ui';
import { type DataBlocksWrapperProps } from '../types';
import DataBlock from './DataBlock';

export const DataBlocksWrapper: React.FC<DataBlocksWrapperProps> = ({ blocks, isEven = true }) => {
  const dataBlocksWrapperRef = useRef<HTMLDivElement>(null);
  const isIntersecting = useScrollPosition(dataBlocksWrapperRef, 0);

  return (
    <div
      className={cn(
        '1280:gap-4 hide-scrollbar wrap inline-grid snap-x snap-mandatory grid-cols-[repeat(11,1fr)] flex-row gap-3 overflow-x-scroll p-0 ',
        isIntersecting &&
          (isEven ? '<768:animate-scroll-wiggle' : '<768:animate-scroll-wiggle-reverse')
      )}
      ref={dataBlocksWrapperRef}
    >
      {blocks?.map(({ _key, ...block }) => <DataBlock key={_key} {...block} />)}
    </div>
  );
};

export default DataBlocksWrapper;
